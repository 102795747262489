//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';

import { PasswordRequirement } from '../components';
import { checkEmail } from '../../services/user';

validate.validators.customPassword = function(value, options, key, attributes) {

    var exps = ['.{8,}', '[a-z]+', '[A-Z]+', '[0-9]+'];
    for (var i = 0; i < exps.length; i++) {
        let exp = exps[i];
        if (!value.match(new RegExp(exp))) {
            return 'must meet the requirements below.';
        }
    }
};

const DEFAULT_PASSWORD = 'N0taPa$$w0RDN0taPa$$w0RDN0taPa$$w0RD';

export default
class AccountInfo extends ViewBase {

    constructor(props) {

        super();

        this.state = {
            emailAddress: '',
            confirmEmailAddress: '',
            password: '',
            confirmPassword: '',
            emailChecked: true
        };


        if (props && props.modelData) {
            this.state = props.modelData;
            this.state.confirmEmailAddress = this.state.emailAddress;
            this.state.originalEmailAddress = this.state.emailAddress;
            this.state.password = props.editMode ? DEFAULT_PASSWORD : '';
            this.state.confirmPassword = props.editMode ? DEFAULT_PASSWORD : '';
        }

        this.constraints = {

            emailAddress: {
                presence: true,
                email: true
            },
            confirmEmailAddress: {
                presence: true,
                equality: 'emailAddress'
            },
            password: {
                customPassword: true
            },
            confirmPassword: {
                presence: true,
                equality: "password"
            },
            emailChecked: {
                presence: true
            }
        };

        if (props && props.modelData) {
            if(!props.resetMode){
                this.constraints.name = {
                    presence: true,
                    length: {
                        minimum: 6
                    }
                };

                this.constraints.verifyPassword = {
                    presence: true,
                    length: {
                        minimum: 8
                    }
                };
            }

            delete this.constraints.password;
            delete this.constraints.confirmPassword;
        }
    }

    /**
     * When the user mouses off the email text field, check the email address to make sure it doesn't already exist in the system.
     */
    onEmailBlur() {
        var self = this;

        // if we're resetting a password, do not check the email address.
        if (self.props.resetMode) {
            self.setState({ emailChecked: true });
            return;
        }

        self.setState({ emailChecked: undefined });
        if (this.state.emailAddress.length > 0 && this.state.emailAddress.indexOf('@') > 0 && this.state.emailAddress.lastIndexOf('.') > this.state.emailAddress.indexOf('@') && this.state.originalEmailAddress !== this.state.emailAddress) {
            checkEmail(this.state.emailAddress).then(function (ok) {
                if (ok) {
                    self.setState({ emailChecked: true });
                }
                else {
                    self.setState({ emailChecked: undefined });
                    alert('The email address that you specified already is registered. If you already have an account with us, you can login to your account with the boxes on the top right of the screen.');
                }
            }, function (err) {
                alert(JSON.stringify(err));
            });
        }
    }

    renderMessage() {
        if (!this.props.editMode && !this.props.resetMode) {
            return <span>Please register an email address you check often so you receive valuable information about your rewards and program participation.<br /><br /></span>;
        }
        return "";
    }

    renderNameField() {
        if (this.props.editMode) {
            return (<div>
                <div className="row">
                    <div className="col-md-4 col-md-offset-2">
                        <label htmlFor="fieldName">Display name</label>
                        <input tabIndex="3" id="fieldName" type="text" className="form-control focus-first" name="name" value={this.state.name} onChange={this.handleChange.bind(this)} />
                        {this.renderValidation("name")}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldVerifyPassword">Verify your current password<span className="required"></span></label>
                        <input ref="fieldVerifyPassword" tabIndex="4" id="fieldVerifyPassword" type="password" className="form-control" name="verifyPassword" onChange={this.handleChange.bind(this)} />
                        {this.renderValidation("verifyPassword")}
                    </div>
                </div>
                <div className="spacing-container"></div>
            </div>);
        }
        else {
            return '';
        }
    }

    render() {
        return (<div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4 col-md-offset-2">
                        <div className="form-group">
                            <label htmlFor="fieldEmail">Please enter your email address<span className="required"></span></label>
                            <input tabIndex="5" onBlur={this.onEmailBlur.bind(this)} id="fieldEmail" type="email" className="form-control focus-first" name="emailAddress" value={this.state.emailAddress} onChange={this.handleChange.bind(this)} />
                            {this.renderValidation("emailAddress")}
                        </div>
                        <div className="form-group">
                            <label htmlFor="fieldEmailConfirm">Confirm your email address<span className="required"></span></label>
                            <input tabIndex="6" id="fieldEmailConfirm" type="email" className="form-control" name="confirmEmailAddress" value={this.state.confirmEmailAddress} onChange={this.handleChange.bind(this)} />
                            {this.renderValidation("confirmEmailAddress")}
                        </div>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <hr/>
                {this.renderNameField()}
                <div className="row">
                    <div className="col-md-4 col-md-offset-2">
                        <label htmlFor="fieldEmail">Please enter your email address<span className="required"></span></label>
                        <input tabIndex="5" onBlur={this.onEmailBlur.bind(this)} id="fieldEmail" type="email" className="form-control focus-first" name="emailAddress" value={this.state.emailAddress} onChange={this.handleChange.bind(this)} />
                        {this.renderValidation("emailAddress")}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldPassword">Create a password<span className="required"></span></label>
                        <input ref="fieldPassword" tabIndex="7" id="fieldPassword" type="password" className="form-control" name="password" onChange={this.handleChange.bind(this)} />
                        {this.renderValidation("password")}
                    </div>
                    <div className="col-md-1"></div>
                </div>
                <div className="spacing-container"></div>
                <div className="row">
                    <div className="col-md-4 col-md-offset-2">
                        <label htmlFor="fieldEmailConfirm">Confirm your email address<span className="required"></span></label>
                        <input tabIndex="6" id="fieldEmailConfirm" type="email" className="form-control" name="confirmEmailAddress" value={this.state.confirmEmailAddress} onChange={this.handleChange.bind(this)} />
                        {this.renderValidation("confirmEmailAddress")}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldPasswordConfirm">Confirm your password<span className="required"></span></label>
                        <input tabIndex="8" id="fieldPasswordConfirm" type="password" className="form-control submit-wizard" name="confirmPassword" onChange={this.handleChange.bind(this)} />
                        {this.renderValidation("confirmPassword")}
                    </div>
                </div>
                <div className="spacing-container"></div>
                <div className="row">
                    <div className="col-md-4 col-md-offset-2">
                        {this.renderMessage()}
                    </div>
                    <div className="col-md-4">
                        <div className="bold">Password requirements:</div>
                        <PasswordRequirement value={this.state.password} exp=".{8,}" message="At least 8 characters long" />
                        <PasswordRequirement value={this.state.password} exp="[a-z]+" message="One lowercase letter" />
                        <PasswordRequirement value={this.state.password} exp="[A-Z]+" message="One UPPERCASE letter" />
                        <PasswordRequirement value={this.state.password} exp="[0-9]+" message="One number" />
                    </div>
                </div>
            </div>
        </div>);
    }
}