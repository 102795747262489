//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';

/**
 * Custom validator to make sure that the consumer is at least 18 years old. Note: there is a server-side check as well.
 * @returns {string}
 */
validate.validators.overEighteen = function(value, options, key, attrs) {

    function getAge(dateString) {
        try {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        }
        catch (e) {
            return 0;
        }
    }

    if (getAge(`${attrs.birthMonth || 12}/${attrs.birthDay || new Date(attrs.birthYear, attrs.birthMonth || 12, 0).getDate()}/${attrs.birthYear}`) < 18) {
        return '- You must be at least 18 years of age to use this service.'
    }
};

var fields = [
    'genderId',
    'birthMonth',
    'birthDay',
    'birthYear',
    'incomeRangeId',
    'maritalStatusId',
    'childrenInHome',
    'adultsInHome',
    'homeStatusId',
    'carStatusId'
];

export default
class DemographicInfo extends ViewBase {

    constructor(props) {
        super();

        this.state = {

        };

        if (props && props.modelData) {
            for (var key in props.modelData) {
                if (props.modelData.hasOwnProperty(key) && fields.indexOf(key) > -1) {
                    this.state[key] = props.modelData[key];
                }
            }
        }

        this.constraints = {
            birthYear: {
                presence: true,
                overEighteen: true
            }
        };
    }
/*
 <h2>Please Tell Us About Yourself&nbsp;<img src="/images/info.png" className="info-icon" /></h2>
 <span>This information is not given to advertisers -  we use it to match you with offers tailored to you.</span>
 */

    render() {
        return (<div>
            <hr />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="fieldBirthdayYear">Birthday&nbsp;-&nbsp;Must be over 18&nbsp;<span className="required-text">(year of birth is required)</span></label>
                        <br />
                        <select tabIndex="3" className="form-control inline" id="fieldBirthdayMonth" name="birthMonth" value={this.state.birthMonth} onChange={this.handleChange.bind(this)}>
                            <option>Choose...</option>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                        <select tabIndex="4" className="form-control inline" id="fieldBirthdayDay" name="birthDay" value={this.state.birthDay} onChange={this.handleChange.bind(this)}>
                            <option>DD</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                        </select>
                        <select tabIndex="5" className="form-control inline focus-first" id="fieldBirthdayYear" name="birthYear" value={this.state.birthYear} onChange={this.handleChange.bind(this)}>
                            <option>YYYY</option>
                            <option>1997</option>
                            <option>1996</option>
                            <option>1995</option>
                            <option>1994</option>
                            <option>1993</option>
                            <option>1992</option>
                            <option>1991</option>
                            <option>1990</option>
                            <option>1989</option>
                            <option>1988</option>
                            <option>1987</option>
                            <option>1986</option>
                            <option>1985</option>
                            <option>1984</option>
                            <option>1983</option>
                            <option>1982</option>
                            <option>1981</option>
                            <option>1980</option>
                            <option>1979</option>
                            <option>1978</option>
                            <option>1977</option>
                            <option>1976</option>
                            <option>1975</option>
                            <option>1974</option>
                            <option>1973</option>
                            <option>1972</option>
                            <option>1971</option>
                            <option>1970</option>
                            <option>1969</option>
                            <option>1968</option>
                            <option>1967</option>
                            <option>1966</option>
                            <option>1965</option>
                            <option>1964</option>
                            <option>1963</option>
                            <option>1962</option>
                            <option>1961</option>
                            <option>1960</option>
                            <option>1959</option>
                            <option>1958</option>
                            <option>1957</option>
                            <option>1956</option>
                            <option>1955</option>
                            <option>1954</option>
                            <option>1953</option>
                            <option>1952</option>
                            <option>1951</option>
                            <option>1950</option>
                            <option>1949</option>
                            <option>1948</option>
                            <option>1947</option>
                            <option>1946</option>
                            <option>1945</option>
                            <option>1944</option>
                            <option>1943</option>
                            <option>1942</option>
                            <option>1941</option>
                            <option>1940</option>
                            <option>1939</option>
                            <option>1938</option>
                            <option>1937</option>
                            <option>1936</option>
                            <option>1935</option>
                            <option>1934</option>
                            <option>1933</option>
                            <option>1932</option>
                            <option>1931</option>
                            <option>1930</option>
                            <option>1929</option>
                            <option>1928</option>
                            <option>1927</option>
                            <option>1926</option>
                            <option>1925</option>
                            <option>1924</option>
                            <option>1923</option>
                            <option>1922</option>
                            <option>1921</option>
                            <option>1920</option>
                            <option>1919</option>
                            <option>1918</option>
                            <option>1917</option>
                            <option>1916</option>
                            <option>1915</option>
                            <option>1914</option>
                            <option>1913</option>
                            <option>1912</option>
                            <option>1911</option>
                            <option>1910</option>
                            <option>1909</option>
                            <option>1908</option>
                            <option>1907</option>
                            <option>1906</option>
                            <option>1905</option>
                            <option>1904</option>
                            <option>1903</option>
                            <option>1902</option>
                            <option>1901</option>
                            <option>1900</option>
                        </select>
                        {this.renderValidation('birthDay')}
                        {this.renderValidation('birthMonth')}
                        {this.renderValidation('birthYear')}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldGender">Gender <span className="optional"></span></label>
                        <select tabIndex="6" className="form-control" id="fieldGender" name="genderId" value={this.state.genderId} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            <option value="F">Female</option>
                            <option value="M">Male</option>
                        </select>
                        {this.renderValidation('genderId')}
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldIncomeRange">Household Income <span className="optional"></span></label>
                        <select tabIndex="7" className="form-control" id="fieldIncomeRange" name="incomeRangeId" value={this.state.incomeRangeId} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            {ViewBase.renderOptions([
                                { id: '1', name: 'Under $30,000' },
                                { id: '2', name: '$30,001 - $50,000' },
                                { id: '3', name: '$50,001 - $70,000' },
                                { id: '4', name: '$70,001 - $90,000' },
                                { id: '5', name: '$90,001 - $120,000' },
                                { id: '6', name: '$120,000+' }
                            ])}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldMaritalStatus">Marital Status <span className="optional"></span></label>
                        <select tabIndex="8" className="form-control" id="fieldMaritalStatus" name="maritalStatusId" value={this.state.maritalStatusId} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            {ViewBase.renderOptions([
                                { id: 'S', name: 'Single' },
                                { id: 'M', name: 'Married' },
                                { id: 'D', name: 'Divorced' },
                                { id: 'L', name: 'Living Together' },
                                { id: 'W', name: 'Widow or Widower' }
                            ])}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldNumberOfChildren">Number of kids in home (under 18) <span className="optional"></span></label>
                        <select tabIndex="9" className="form-control" id="fieldNumberOfChildren" name="childrenInHome" value={this.state.childrenInHome} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            {ViewBase.renderOptions([
                                { id: '0', name: 'No children' },
                                { id: 'S', name: '1 - 2 children' },
                                { id: 'L', name: '3+ children' }
                            ])}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldNumberOfAdults">Number of adults in home <span className="optional"></span></label>
                        <select tabIndex="10" className="form-control" id="fieldNumberOfAdults" name="adultsInHome" value={this.state.adultsInHome} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            {ViewBase.renderOptions([
                                { id: '0', name: '1 adult' },
                                { id: 'S', name: '2 adults' },
                                { id: 'L', name: '3+ adults' }
                            ])}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldHomeStatus">Home? <span className="optional"></span></label>
                        <select tabIndex="11" className="form-control" id="fieldHomeStatus" name="homeStatusId" value={this.state.homeStatusId} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            {ViewBase.renderOptions([
                                { id: 'O', name: 'Own' },
                                { id: 'R', name: 'Rent' }
                            ])}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="fieldCarStatus">Car? <span className="optional"></span></label>
                        <select tabIndex="12" className="form-control submit-wizard" id="fieldCarStatus" name="carStatusId" value={this.state.carStatusId} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            {ViewBase.renderOptions([
                                { id: 'N', name: 'None' },
                                { id: 'L', name: 'Lease' },
                                { id: 'O', name: 'Own' }
                            ])}
                        </select>
                    </div>
                </div>
            </div>
        </div>);
    }
}