import React from 'react';

import Consumer from '../consumer';
import User from '../user';
import { handleError, getParameterByName } from '../../utils';
import { LoginDisplay } from '../../views/components';
import { isAuthenticated, logError } from '../../services/session';
import { AccountInfo } from '../../views/user';
import { Radio, Checkbox, PasswordRequirement, PhoneNumber } from '../components';
import ViewBase from '../ViewBase.jsx';


export default
/**
 * Represents the entire consumer registration page including all steps.
 */
class HomeNew extends ViewBase {

  constructor() {
    super();
  }

  componentDidMount() {
    super.componentDidMount();
  }

  goHealth() {
    window.location = '/register/consumer-4/?type=health';
  }

  goIns() {
    window.location = '/register/consumer-4/?type=ins'
  }

  goSolar() {
    window.location = '/register/consumer-4/?type=solar'
  }

  goOther() {
    window.location = '/register/consumer-4/?type=other'
  }

  render() {
    return (<div id="container-fluid">
      <div id="header-wrap">
        <div className="container">
          <header id="header" className="clearfix">
            <div className="row">
              <div className="col-sm-4">
                <div className="logo"><a href="#"><img src="/content/images/optin-images/logo.png" alt="" /></a></div>
              </div>
              <div className="col-sm-8">
                <div className="bs-docs-nav navbar navbar-static-top" id="top">
                  <div className="navbar-header">
                    <button aria-controls="bs-navbar" aria-expanded="false" className="collapsed navbar-toggle"
                            data-target="#bs-navbar" data-toggle="collapse" type="button">
                      <span className="sr-only">Toggle navigation</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <nav className="collapse navbar-collapse" id="bs-navbar">
                    <ul className="nav navbar-nav">
                      <li><a href="/home/what-we-do-6">About Us</a></li>
                      <li className="join-now"><a href="#category-section" className="scroll">Join Now</a></li>
                      <li className="dropdown">
                        <a href="#" className="dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Login</a>
                        <ul className="dropdown-menu">
                          <li>
                            <LoginDisplay refresh="true"></LoginDisplay>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div className="cl"></div>
              </div>
            </div>
          </header>
        </div>
      </div>
      <div className="banner-wrap">
        <div className="container">
          <div className="row">
            <div className="col-sm-4"></div>
            <div className="col-sm-8">
              <div className="banner-content">
                <h1>
                  <span>Simplify the hassle of buying </span>
                  <span>complicated products while </span>
                  protecting your privacy
                </h1>
                <p>
                  Speak anonymously &amp; conveniently with someone over the phone to help make your next major purchase, like buying insurance, a residential solar system, and many other products.
                </p>
                <p>
                  <a href="#category-section" className="btn btn-lg btn-primary btn-round scroll">JOIN FOR FREE</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <a href="#banner-control" className="arrow-bot arrow-dark scroll"></a>
      </div>
      <div className="banner-control-wrap" id="banner-control">
        <div className="container">
          <div className="banner-control-inner">
            <h2>
              <span>Take <em>control</em> over a </span>
              frustrating buying process.
            </h2>
            <p>
              Control your calendar with scheduled phone calls that get you the
              product information you need, when and how you want it.
            </p>
          </div>
        </div>
        <div className="img-wrap">
          <img src="/content/images/optin-images/hand-image.png" alt="" />
        </div>
        <a href="#banner-privacy" className="arrow-bot scroll"></a>
      </div>
      <div className="banner-privacy-wrap" id="banner-privacy">
        <div className="container">
          <div className="banner-privacy-inner">
            <h2>
              <span>Privacy and control.</span>
              Plus rewards.
            </h2>
            <p>
              Every Optincall you take earns you credit for cash or gift cards.  Our sweepstakes and other benefits help get you extra cashola.  And joining is free!
            </p>
            <div className="card-seciton">
              <p>
                <a href="#"><img src="/content/images/optin-images/card-1.png" alt="" /></a>
                <a href="#"><img src="/content/images/optin-images/card-2.png" alt="" /></a>
                <a href="#"><img src="/content/images/optin-images/card-3.png" alt="" /></a>
              </p>
            </div>
          </div>
          <div className="cl"></div>
        </div>
        <a href="#category-section" className="arrow-bot arrow-dark scroll"></a>
      </div>
      <div className="category-section-wrap" id="category-section">
        <div className="container">
          <div className="category-section">
            <h2>What category is your next major purchase in?</h2>
            <div className="row">
              <div className="col-lg-8 col-md-6">
                <div className="image-section section-1">
                  <a href="#" onClick={this.goIns}>
                    <img src="/content/images/optin-images/image-1.jpg" alt="" />
                    <em className="overlay"></em>
                    <span className="txt-overlay">Life <br /> insurance</span>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="image-section section-2">
                  <a href="#" onClick={this.goHealth}>
                    <img src="/content/images/optin-images/image-2.jpg" alt="" />
                    <em className="overlay"></em>
                    <span className="txt-overlay">Health <br /> insurance</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 col-md-6">
                <div className="image-section section-3">
                  <a href="#" onClick={this.goSolar}>
                    <img src="/content/images/optin-images/image-3.jpg" alt="" />
                    <em className="overlay"></em>
                    <span className="txt-overlay">Energy saving / <br /> solar</span>
                  </a>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="image-section section-4">
                  <a href="#" onClick={this.goOther}>
                    <img src="/content/images/optin-images/image-4.jpg" alt="" />
                    <em className="overlay"></em>
                    <span className="txt-overlay">More Products &amp;  <br /> Services</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>);
  }
}
