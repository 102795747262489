//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';
import { Radio, Checkbox, PhoneNumber } from '../components';
import jstz from 'jstz';
import { untranslateTimezone } from '../../services/utils';
import { checkPhone } from '../../services/consumer';

validate.validators.chooseOne = function(value, options, key, attrs) {

    for (var attr in attrs) {
        var prefixes = Array.isArray(options.prefix) ? options.prefix : [options.prefix];
        for (var i = 0; i < prefixes.length; i++) {
            let prefix = prefixes[i];
            if (attrs.hasOwnProperty(attr) && attr.indexOf(prefix) === 0) {
                if (attrs[attr]) {
                    return null;
                }
            }
        }
    }

    return 'requires at least one choice.';
};

export default
class CallingInfo extends ViewBase {

    constructor(props) {
        super();

        this.state = {
            timezone: '',
            useArizonaTime: false,
            timeOfDay8to10AM: false,
            timeOfDay10to12: false,
            timeOfDay12to2: false,
            timeOfDay2to4: false,
            timeOfDay4to6: false,
            timeOfDay6to8: false,
            timeOfDay8to10PM: false,
            timeOfWeekMonday: false,
            timeOfWeekTuesday: false,
            timeOfWeekWednesday: false,
            timeOfWeekThursday: false,
            timeOfWeekFriday: false,
            timeOfWeekSaturday: false,
            timeOfWeekSunday: false,
            categoryEdu1: false,
            categoryEdu2: false,
            categoryEdu3: false,
            categoryEdu4: false,
            categoryIns1: false,
            categoryIns2: false,
            categoryIns3: false,
            categoryIns4: false,
            categoryIns5: false,
            categoryIns6: false,
            categoryFin1: false,
            categoryFin2: false,
            categoryFin3: false,
            categoryFin4: false,
            categoryFin5: false,
            categoryFin6: false,
            categoryFin7: false,
            categoryUtl1: false,
            categoryUtl2: false,
            categoryUtl3: false,
            categoryUtl4: false,
            categoryHml1: false,
            categoryHml2: false,
            categoryHml3: false,
            categoryHml4: false,
            categoryHml5: false,
            categoryPoll: false,
            categoryFood: false,
            suggestCategory: false
        };

        var tz = untranslateTimezone(jstz.determine().name());
        this.state.timezone = tz.timezone;
        this.state.useArizonaTime = tz.useArizonaTime;

        if (props && props.modelData) {
            this.state = props.modelData;
            delete this.state.category_E;
            delete this.state.category_I;
            delete this.state.category_F;
            delete this.state.category_U;
            delete this.state.category_H;
        }

        this.constraints = {
            timezone: {
                presence: true,
                inclusion: ['Eastern', 'Central', 'Mountain', 'Pacific']
            },
            timeOfDay: { // made up "field"
                chooseOne: {
                    prefix: 'timeOfDay'
                }
            },
            timeOfWeek: { // made up "field"
                chooseOne: {
                    prefix: 'timeOfWeek'
                }
            },
            maxCallsPerMonth: {
                presence: true,
                numericality: {
                    onlyInteger: true
                }
            },
            categories: { // made up "field"
                chooseOne: {
                    prefix: ['category', 'subcategory']
                }
            },
            mobileNumber: {
                presence: true,
                length: {is: 10},
                numericality: {
                    onlyInteger: true
                }
            },
            phoneChecked: {
                presence: true
            }
        };

        if (props.hidePhone) {
            delete this.constraints.mobileNumber;
            delete this.constraints.phoneChecked;
        }

        this.optionType = {
            subcategory_E1: 'select',
            subcategory_I1: 'select',
            //subcategory_I2: 'select',
            //subcategory_I3: 'select',
            //subcategory_I4: 'select',
            subcategory_I6: 'select',
            subcategory_F3: 'select',
            subcategory_U2: 'select',
            subcategory_U4: 'mobileCarrier',
            subcategory_H3: 'select',
            subcategory_H6: 'text'
        };

        this.optionMap = {
            subcategory_E1: 'degreeProgramCarrier',
            subcategory_I1: 'autoInsuranceCarrier',
            //subcategory_I2: 'homeInsuranceCarrier',
            //subcategory_I3: 'rentalInsuranceCarrier',
            //subcategory_I4: 'lifeInsuranceCarrier',
            subcategory_I6: 'healthInsuranceCarrier',
            subcategory_F3: 'bank',
            subcategory_U2: 'satelliteCarrier',
            subcategory_U4: 'mobileCarrier',
            subcategory_H3: 'homeSecurityCarrier',
            subcategory_H6: 'buySellHomeRegion'
        };

        this.optionQuestion = {
            subcategory_E1: 'Your current provider:',
            subcategory_I1: 'Your current provider:',
            //subcategory_I2: 'Your current provider:',
            //subcategory_I3: 'Your current provider:',
            //subcategory_I4: 'Your current provider:',
            subcategory_I6: 'Your current provider:',
            subcategory_F3: 'Your current provider:',
            subcategory_U2: 'Your current provider:',
            subcategory_U4: 'Your current provider:',
            subcategory_H3: 'Your current provider:',
            subcategory_H6: 'Buy/Sell in what city?'
        };

        this.options = {
            // degree programs
            subcategory_E1: ['AA','Bachelors','Masters','PhD'],
            // auto insurance
            subcategory_I1: [
                'State Farm',
                'Allstate',
                'Geico',
                'Liberty Mutual',
                'Progressive',
                'American Family',
                'Erie Insurance',
                'Nationwide',
                'Farmers',
                'Country Financial',
                'Auto-Owners Insurance',
                'Amica',
                'AAA',
                '21st Century',
                'USAA',
                'Mercury',
                'Hartford',
                'AIG',
                'The General',
                'Kemper',
                'Travelers',
                'Freeway'
            ],
            // home insurance
            subcategory_I2: [
            ],
            // rental insurance
            subcategory_I3: [
            ],
            // life insurance
            subcategory_I4: [
            ],
            // health insurance
            subcategory_I6: [
                'Blue Cross Blue Shield', 'Aetna', 'United Healthcare', 'Cigna'
            ],
            // bank
            subcategory_F3: ['Bank of America','Chase','Wells Fargo','Online Banking'],
            // Satellite
            subcategory_U2: ['Dish Network', 'DirecTV'],
            // cell phone carrier
            subcategory_U4: ['AT&T','Verizon','Sprint','T-Mobile','Virgin','Other'],
            // security systems
            subcategory_H3: [
                'AT&T', 'Brinks', 'Guardian', 'Comcast/XFinity', 'Brighthouse', 'ADT', 'Honeywell', 'Vivint', 'Lifeshield/DirecTV', 'Link', 'Protection One', 'Protect America', 'Time Warner'
            ]
        }
    }

    /**
     * When the user types the phone number, check the number to make sure it doesn't already exist in the system.
     */
    onPhoneChange() {
        var self = this;
        self.setState({ phoneChecked: undefined });
        if (this.state.mobileNumber.length === 10) {
            checkPhone(this.state.mobileNumber).then(function (ok) {
                if (ok) {
                    self.setState({ phoneChecked: true });
                }
                else {
                    self.setState({ phoneChecked: undefined });
                    alert('The phone number that you specified already is registered. If you already have an account with us, you can login to your account with the boxes on the top right of the screen.');
                }
            });
        }
    }

    renderArizonaTimeCheckbox() {
        if (this.state.timezone === 'Mountain') {
            return (<div className="checkbox">
                <input type="checkbox" id="fieldUseArizonaTime" name="useArizonaTime" checked={this.state.useArizonaTime} onChange={this.handleChange.bind(this)} />
                <label htmlFor="fieldUseArizonaTime" style={{marginTop: '0px', marginLeft: '20px'}}>
                    Use Arizona Time?
                </label>
            </div>);
        }
        return '';
    }

    renderAdditionalQuestionOptions(field) {
        return [
            <option>Select an option...</option>,
            <option value='N/A'>N/A</option>
            ].concat(this.options[field].map(i => <option value={i}>{i}</option>));
    }

    renderAdditionalQuestion(field) {
        if (this.optionType[field] && this.state[field]) {
            if (this.optionType[field] === 'select') {
                return (<div>
                    {this.optionQuestion[field]}<br />
                    <select className="form-control" name={this.optionMap[field]}
                            value={this.state[this.optionMap[field]]} onChange={this.handleChange.bind(this)}>
                        {this.renderAdditionalQuestionOptions(field)}
                    </select>
                </div>);
            }
            else {
                return (<div>
                    {this.optionQuestion[field]}<br />
                    <input className="form-control" name={this.optionMap[field]}
                            value={this.state[this.optionMap[field]]} onChange={this.handleChange.bind(this)} />
                </div>);
            }
        }
        return '';
    }

    renderPhone() {
        if (!this.props.hidePhone) {
            return (<div>
                <hr />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            Mobile Number<span className="required"></span><br /><br />
                            <PhoneNumber target={this} name="mobileNumber" onChange={this.onPhoneChange.bind(this)} />
                            {this.renderValidation('mobileNumber')}
                        </div>
                        <div className="col-md-6">
                            Enter your mobile number. A text message with a verification
                            code will be sent to your mobile number after you press the "Finish" button below. You will
                            be directed to enter the verification code on the next page.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <Checkbox name="doNotCallRegistry" value="Please add this mobile number to the Federal Trade Commission's Do Not Call Registry, so that I will not receive unsolicited commercial phone calls. I understand there is no charge for this service, and that I will continue to receive calls from Optincall based on my calling preferences." target={this} />
                        </div>
                    </div>
                </div>
            </div>);
        }
        return '';
    }

    render() {
        return (<div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        What times to call me<span title="choose at least one" className="required"></span><br />
                        <Radio name="timezone" value="Pacific" target={this} className="focus-first" />
                        <Radio name="timezone" value="Mountain" target={this} />
                        {this.renderArizonaTimeCheckbox()}
                        <Radio name="timezone" value="Central" target={this} />
                        <Radio name="timezone" value="Eastern" target={this} />
                        {this.renderValidation('timezone')}
                    </div>
                    <div className="col-md-3" style={{paddingTop: '22px'}}>
                        <div className="left" style={{'width': '50%'}}>
                            <Checkbox name="timeOfDay8to10AM" value="8-10 am" target={this} />
                            <Checkbox name="timeOfDay10to12AM" value="10-12 am" target={this} />
                            <Checkbox name="timeOfDay12to2PM" value="12-2 pm" target={this} />
                            <Checkbox name="timeOfDay2to4PM" value="2-4 pm" target={this} />
                        </div>
                        <div className="right" style={{width: '50%'}}>
                            <Checkbox name="timeOfDay4to6PM" value="4-6 pm" target={this} />
                            <Checkbox name="timeOfDay6to8PM" value="6-8 pm" target={this} />
                            <Checkbox name="timeOfDay8to10PM" value="8-10 pm" target={this} />
                        </div>
                        <div style={{clear: 'both'}}>
                            {this.renderValidation('timeOfDay')}
                        </div>
                    </div>
                    <div className="col-md-3" style={{overflow: 'hidden'}}>
                        What days to call me<span title="choose at least one" className="required"></span><br />
                        <div className="left" style={{width: '50%'}}>
                            <Checkbox name="timeOfWeekMonday" value="Monday" target={this} />
                            <Checkbox name="timeOfWeekTuesday" value="Tuesday" target={this} />
                            <Checkbox name="timeOfWeekWednesday" value="Wednesday" target={this} />
                            <Checkbox name="timeOfWeekThursday" value="Thursday" target={this} />
                            <Checkbox name="timeOfWeekFriday" value="Friday" target={this} />
                        </div>
                        <div className="right" style={{width: '50%'}}>
                            <Checkbox name="timeOfWeekSaturday" value="Saturday" target={this} />
                            <Checkbox name="timeOfWeekSunday" value="Sunday" target={this} />
                        </div>
                        <div style={{clear: 'both'}}>
                            {this.renderValidation('timeOfWeek')}
                        </div>
                    </div>
                    <div className="col-md-3">
                        Max calls per month<span title="choose at least one" className="required"></span><br />
                        <select className="form-control" style={{marginTop: '22px'}} name="maxCallsPerMonth" value={this.state.maxCallsPerMonth} onChange={this.handleChange.bind(this)}>
                            <option>Select an option...</option>
                            <option>1</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                            <option>6</option>
                            <option>7</option>
                            <option>8</option>
                            <option>9</option>
                            <option>10</option>
                        </select>
                        {this.renderValidation('maxCallsPerMonth')}
                    </div>
                </div>
            </div>
            <hr />
            Call me about<span title="choose at least one" className="required"></span><br />
            <div className="container-fluid" style={{marginTop: '10px'}}>
                <div className="row" style={{marginBottom: '20px'}}>
                    <div className="col-md-2">
                        EDUCATION<br />
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_1_1" name="subcategory_E1" checked={this.state.subcategory_E1} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_1_1">
                                Degree Programs
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_E1')}
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_1_2" name="subcategory_E2" checked={this.state.subcategory_E2} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_1_2">
                                Vocational/Technical Programs
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_1_3" name="subcategory_E3" checked={this.state.subcategory_E3} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_1_3">
                                High School Test Prep
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_1_4" name="subcategory_E4" checked={this.state.subcategory_E4} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_1_4">
                                Higher Ed Test Prep
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        INSURANCE<br />
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_1" name="subcategory_I1" checked={this.state.subcategory_I1} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_1">
                                Auto Insurance
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_I1')}
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_2" name="subcategory_I2" checked={this.state.subcategory_I2} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_2">
                                Homeowner Insurance
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_3" name="subcategory_I3" checked={this.state.subcategory_I3} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_3">
                                Rental Insurance
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_4" name="subcategory_I4" checked={this.state.subcategory_I4} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_4">
                                Life Insurance
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_5" name="subcategory_I5" checked={this.state.subcategory_I5} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_5">
                                Long Term Care
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_6" name="subcategory_I6" checked={this.state.subcategory_I6} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_6">
                                Health Insurance
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_I6')}
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_7" name="subcategory_I7" checked={this.state.subcategory_I7} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_7">
                                Disability Insurance
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_I7')}
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_2_8" name="subcategory_I8" checked={this.state.subcategory_I8} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_2_8">
                                Annuities
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_I8')}
                    </div>
                    <div className="col-md-2">
                        FINANCIAL SERVICES<br />
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_1" name="subcategory_F1" checked={this.state.subcategory_F1} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_1">
                                Home Refinancing
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_2" name="subcategory_F2" checked={this.state.subcategory_F2} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_2">
                                Home Mortgage
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_3" name="subcategory_F3" checked={this.state.subcategory_F3} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_3">
                                Banking
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_F3')}
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_4" name="subcategory_F4" checked={this.state.subcategory_F4} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_4">
                                Brokerage
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_5" name="subcategory_F5" checked={this.state.subcategory_F5} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_5">
                                Student Loan Consolidation
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_6" name="subcategory_F6" checked={this.state.subcategory_F6} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_6">
                                Credit Card Consolidation
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_7" name="subcategory_F7" checked={this.state.subcategory_F7} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_7">
                                Reverse Mortgage
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_3_8" name="subcategory_F8" checked={this.state.subcategory_F8} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_3_8">
                                Auto Warranty
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        UTILITIES<br />
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_4_1" name="subcategory_U1" checked={this.state.subcategory_U1} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_4_1">
                                Cable
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_4_2" name="subcategory_U2" checked={this.state.subcategory_U2} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_4_2">
                                Satellite
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_U2')}
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_4_3" name="subcategory_U3" checked={this.state.subcategory_U3} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_4_3">
                                High Speed Internet
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_4_4" name="subcategory_U4" checked={this.state.subcategory_U4} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_4_4">
                                Cellular Phone Service
                            </label>
                        </div>
                    </div>
                    <div className="col-md-2">
                        HOME &amp; LEISURE<br />
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_5_1" name="subcategory_H1" checked={this.state.subcategory_H1} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_5_1">
                                Energy Savings
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_5_2" name="subcategory_H2" checked={this.state.subcategory_H2} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_5_2">
                                Remodeling Services
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_5_3" name="subcategory_H3" checked={this.state.subcategory_H3} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_5_3">
                                Home Security
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_H3')}
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_5_4" name="subcategory_H4" checked={this.state.subcategory_H4} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_5_4">
                                Audio Equipment
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_5_5" name="subcategory_H5" checked={this.state.subcategory_H5} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_5_5">
                                Mobile Devices
                            </label>
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_5_6" name="subcategory_H6" checked={this.state.subcategory_H6} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_5_6">
                                Buy or Sell a Home
                            </label>
                        </div>
                        {this.renderAdditionalQuestion('subcategory_H6')}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                        POLLING &amp; SURVEYS<br />
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_6" name="category_P" checked={this.state.category_P} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_6"></label>
                        </div>
                        {this.renderValidation('categories')}
                    </div>
                    <div className="col-md-2">
                        RESTAURANT PROMOTIONS<br />
                        <div className="checkbox">
                            <input type="checkbox" id="fieldCategory_7" name="category_R" checked={this.state.category_R} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_7"></label>
                        </div>
                    </div>
                    <div className="col-md-3">
                        SUGGEST YOUR OWN CATEGORY:<br />
                        <div className="checkbox" style={{float: 'left'}}>
                            <input type="checkbox" id="fieldCategory_8" name="suggestCategory" checked={this.state.suggestCategory} onChange={this.handleChange.bind(this)} />
                            <label htmlFor="fieldCategory_8"></label>
                        </div>
                        <input type="text" style={{maxWidth: '300px', marginTop: '12px'}} className="form-control inline" name="suggestCategoryValue" value={this.state.suggestCategoryValue} onChange={this.handleChange.bind(this)} />
                    </div>
                </div>
            </div>
            {this.renderPhone()}
        </div>);
    }
}