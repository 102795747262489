//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';
import {updateUser} from '../../services/user';
import {PasswordRequirement} from '../components';

validate.validators.customPassword = function (value, options, key, attributes) {

    var exps = ['.{8,}', '[a-z]+', '[A-Z]+', '[0-9]+'];
    for (var i = 0; i < exps.length; i++) {
        let exp = exps[i];
        if (!value.match(new RegExp(exp))) {
            return 'must meet the requirements below.';
        }
    }
}

const DEFAULT_PASSWORD = 'N0taPa$$w0RDN0taPa$$w0RDN0taPa$$w0RD';

export default
class Password extends ViewBase {

    constructor(props) {

        super();

        this.state = {
            password: '',
            confirmPassword: '',
            verifyPassword: '',
            errors: {}
        };

        this.constraints = {
            password: {
                customPassword: true
            },
            confirmPassword: {
                presence: true,
                equality: "password"
            },
        };

        if (props && props.modelData) {
            if (!props.resetMode) {
                this.constraints.verifyPassword = {
                    presence: true,
                    length: {
                        minimum: 8
                    }
                };
            }

            delete this.constraints.password;
            delete this.constraints.confirmPassword;
        }
    }

    onSaveChangePassword() {
        var model = this.state

        var errors = {}
        if (model.verifyPassword.length === 0) {
            errors.verifyPassword = 'Please set current password'
        }
        if (model.password !== model.confirmPassword) {
            errors.confirmPassword = 'Passwords must be the same'
        }
        if (model.password.length < 8) {
            errors.password = 'Passwords must contain minimum 8 characters'
        }
        if (!model.password.match(/[a-z]/g)) {
            errors.password = 'Passwords must contain lowercase letter'
        }
        if (!model.password.match(/[A-Z]/g)) {
            errors.password = 'Passwords must contain uppercase letter'
        }
        if (!model.password.match(/[0-9]/g)) {
            errors.password = 'Passwords must contain number'
        }

        this.setState({
            errors: errors
        })


        if (Object.keys(errors).length === 0) {
            updateUser(model)
                .then(() => {
                    this.setState({
                        saving: true
                    })
                })
                .catch((err) => {
                    this.setState({
                        saving: false
                    })
                    handleError(err)
                });
        }
    }

    render() {
        return (
            <div className="col-md-4  col-md-offset-1">
                <fieldset>
                    <legend>Change password:</legend>
                    <div className="alert alert-success "
                         style={{display: (this.state.saving === true) ? 'block' : 'none'}}
                         role="alert">
                        Password updated
                    </div>
                    <div className="alert alert-danger"
                         style={{display: (this.state.saving === false) ? 'block' : 'none'}}
                         role="alert">
                        Updating Password Failed
                    </div>
                    <div className="form-group">
                        <label htmlFor="fieldVerifyPassword">
                            Verify your current password<span className="required"/>
                        </label>
                        <input ref="fieldVerifyPassword" tabIndex="4" id="fieldVerifyPassword" type="password"
                               className="form-control" name="verifyPassword" onChange={this.handleChange.bind(this)}/>
                        {this.renderValidation("verifyPassword")}
                    </div>
                    <div className="form-group">
                        <label htmlFor="fieldPassword">
                            Create a password<span className="required"/>
                        </label>
                        <input ref="fieldPassword" tabIndex="7" id="fieldPassword" type="password"
                               className="form-control"
                               name="password" onChange={this.handleChange.bind(this)}/>
                        {this.renderValidation("password")}
                    </div>
                    <div className="form-group">
                        <label htmlFor="fieldPasswordConfirm">
                            Confirm your password<span className="required"/>
                        </label>
                        <input tabIndex="8" id="fieldPasswordConfirm" type="password"
                               className="form-control submit-wizard"
                               name="confirmPassword" onChange={this.handleChange.bind(this)}/>
                        {this.renderValidation("confirmPassword")}
                    </div>
                    <div className="form-group">
                        <div className="bold">Password requirements:</div>
                        <PasswordRequirement value={this.state.password} exp=".{8,}"
                                             message="At least 8 characters long"/>
                        <PasswordRequirement value={this.state.password} exp="[a-z]+" message="One lowercase letter"/>
                        <PasswordRequirement value={this.state.password} exp="[A-Z]+" message="One UPPERCASE letter"/>
                        <PasswordRequirement value={this.state.password} exp="[0-9]+" message="One number"/>
                    </div>
                    <div className="form-group">
                        <button className="btn btn-success btn-lg" onClick={this.onSaveChangePassword.bind(this)}>
                            Change Password
                        </button>
                    </div>
                </fieldset>
            </div>
        );
    }
}