//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx'
import { Address, Checkbox } from '../components';

var fields = [
    'firstName',
    'middleName',
    'lastName',
    'address1',
    'address2',
    'city',
    'state',
    'zipCode',
    'pinCode'
];

function randomInt (low, high) {
    return Math.floor(Math.random() * (high - low + 1) + low);
}

export default
class ContactInfo extends ViewBase {

    constructor(props) {
        super();

        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            shareNameWithAdvertiser: false,
            address1: '',
            address2: '',
            city: '',
            state: '',
            zipCode: '',
            blockCode: randomInt(200, 999).toString(),
            pinCode: ''
        };

        if (props && props.modelData) {
            for (var key in props.modelData) {
                if (props.modelData.hasOwnProperty(key) && fields.indexOf(key) > -1) {
                    this.state[key] = props.modelData[key];
                }
            }
            this.state.pinCode = ("0000" + this.state.pinCode).substr(-4);
            this.state.blockCode = props.modelData.blockCode; // we want to keep the original block code.
        }

        this.constraints = {
            firstName: {
                presence: true,
                length: {
                    minimum: 3
                }
            },
            lastName: {
                presence: true,
                length: {
                    minimum: 2
                }
            },
            zipCode: {
                presence: true,
                length: {
                    is: 5
                },
                numericality: {
                    onlyInteger: true
                }
            },
            pinCode: {
                presence: true,
                length: {
                    is: 4
                },
                numericality: {
                    onlyInteger: true
                }
            }
        }
    }

    render() {
        return (<div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <label htmlFor="fieldFirstName">First Name<span className="required"></span></label>
                        <input tabIndex="3" id="fieldFirstName" type="text" className="form-control focus-first" name="firstName" value={this.state.firstName} onChange={this.handleChange.bind(this)} />
                        <Checkbox target={this} name="shareNameWithAdvertiser" value="Provide first name to advertiser?" />
                        {this.renderValidation("firstName")}
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="fieldMiddleName">Middle Name<span className="optional"></span></label>
                        <input tabIndex="4" id="fieldMiddleName" type="text" className="form-control" name="middleName" value={this.state.middleName} onChange={this.handleChange.bind(this)} />
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="fieldLastName">Last Name<span className="required"></span></label>
                        <input tabIndex="5" id="fieldLastName" type="text" className="form-control" name="lastName" value={this.state.lastName} onChange={this.handleChange.bind(this)} />
                        {this.renderValidation("lastName")}
                    </div>
                </div>
                <Address target={this} tabIndex={6} />
                <hr />
                <div className="row" style={{marginTop: '20px'}}>
                    <div className="col-md-6" style={{ textAlign: 'left' }}>
                        <h2>Please Choose a 4 Digit Caller ID</h2>
                        <div style={{margin: 'auto', overflow: 'hidden', minWidth: '300px'}}>
                            <div style={{ fontSize: '28pt', float: 'left' }}>(200)&nbsp;{this.state.blockCode}&nbsp;-&nbsp;</div>
                            <input tabIndex="11" type="tel" className="form-control inline no-spin submit-wizard" style={{ width: '130px', height: '50px', fontSize: '28px', float: 'left', margin: '11px', marginTop: '5px' }} name="pinCode" value={this.state.pinCode} onChange={this.handleChange.bind(this)} maxLength="4" />
                            <span className="required"></span>
                            {this.renderValidation("pinCode")}
                        </div>
                    </div>
                    <div className="col-md-6" style={{paddingTop: '35px'}}>
                        Every Optincall to you will be from the same phone number, ending in your 4 digit code. If you don't recognize the code, it isn't Optincall ringing you.
                        <br /><br />
                        Please use numbers only, don't use a bank PIN!<br />
                    </div>
                    <div className="col-md-4">
                    </div>
                </div>
            </div>
        </div>);
    }
}