//******************************************************************************
//*                                                                            *
//* (C) 2015 OptInCall Inc.                                                    *
//*                                                                            *
//* All rights reserved. Reproduction or transmission in whole or in part, in  *
//* any form or by any means, electronic, mechanical, or otherwise, is         *
//* prohibited without the prior written permission of the copyright owner.    *
//******************************************************************************

import React from 'react';
import ViewBase from '../ViewBase.jsx';
import {checkEmail, updateUser} from '../../services/user';
import {handleError} from '../../utils';

validate.validators.customPassword = function (value, options, key, attributes) {

    var exps = ['.{8,}', '[a-z]+', '[A-Z]+', '[0-9]+'];
    for (var i = 0; i < exps.length; i++) {
        let exp = exps[i];
        if (!value.match(new RegExp(exp))) {
            return 'must meet the requirements below.';
        }
    }
};

export default
class Email extends ViewBase {

    constructor(props) {

        super();

        this.state = {
            emailAddress: '',
            confirmEmailAddress: '',
            saving: null
        };


        if (props && props.modelData) {
            this.state = props.modelData;
            this.state.confirmEmailAddress = this.state.emailAddress;
            this.state.originalEmailAddress = this.state.emailAddress;
        }

        this.constraints = {
            emailAddress: {
                presence: true,
                email: true
            },
            confirmEmailAddress: {
                presence: true,
                equality: 'emailAddress'
            }
        };
    }

    /**
     * When the user mouses off the email text field, check the email address to make sure it doesn't already exist in the system.
     */
    onEmailBlur() {
        var self = this;

        self.setState({emailChecked: undefined});
        if (this.state.emailAddress.length > 0 && this.state.emailAddress.indexOf('@') > 0 && this.state.emailAddress.lastIndexOf('.') > this.state.emailAddress.indexOf('@') && this.state.originalEmailAddress !== this.state.emailAddress) {
            checkEmail(this.state.emailAddress).then(function (ok) {
                if (ok) {
                    self.setState({emailChecked: true});
                }
                else {
                    self.setState({emailChecked: undefined});
                    alert('The email address that you specified already is registered. If you already have an account with us, you can login to your account with the boxes on the top right of the screen.');
                }
            }, function (err) {
                alert(JSON.stringify(err));
            });
        }
    }

    renderMessage() {
        if (!this.props.editMode && !this.props.resetMode) {
            return <span>Please register an email address you check often so you receive valuable information about your rewards and program participation.<br /><br /></span>;
        }
        return "";
    }

    onSaveChangeEmail() {
        var model = this.state

        updateUser(model)
            .then(() => {
                this.setState({
                    saving: true
                })
            })
            .catch((err) => {
                this.setState({
                    saving: false
                })
                handleError(err)
            });

    }

    render() {
        return (
            <div className="col-md-4 col-md-offset-2">
                <fieldset>
                    <legend>Change email:</legend>
                    <div className="alert alert-success "
                         style={{display: (this.state.saving === true) ? 'block' : 'none'}}
                         role="alert">
                        Email address updated<br/>
                        Please check your inbox to verify.
                    </div>
                    <div className="alert alert-danger"
                         style={{display: (this.state.saving === false) ? 'block' : 'none'}}
                         role="alert">
                        Updating Email Failed
                    </div>
                    <div className="form-group">
                        <label htmlFor="fieldEmail">
                            Please enter your email address<span className="required"/>
                        </label>
                        <input tabIndex="5" onBlur={this.onEmailBlur.bind(this)} id="fieldEmail" type="email"
                               className="form-control focus-first" name="emailAddress"
                               value={this.state.emailAddress} onChange={this.handleChange.bind(this)}/>
                        {this.renderValidation("emailAddress")}
                    </div>
                    <div className="form-group">
                        <label htmlFor="fieldEmailConfirm">
                            Confirm your email address<span className="required"/>
                        </label>
                        <input tabIndex="6" id="fieldEmailConfirm" type="email" className="form-control"
                               name="confirmEmailAddress" value={this.state.confirmEmailAddress}
                               onChange={this.handleChange.bind(this)}/>
                        {this.renderValidation("confirmEmailAddress")}
                    </div>
                    <div className="form-group">
                        <button className="btn btn-success btn-lg" onClick={this.onSaveChangeEmail.bind(this)}>
                            Change Email
                        </button>
                    </div>
                </fieldset>
            </div>
        );
    }
}